<template>
    <div class="">
        <div class="zi-1 rectangle-bghead d-none d-lg-block">
        </div>
        <v-container>
            <div class="py-10 py-md-10  my-md-10">
                <h1 class="h1 montserrat text-center firstletterblue text-md-left">Technologies</h1>
                <p class="my-6 opensans col-12 col-md-8 text-center  text-md-left" ref="header">You set the destination, and we take care of everything else. There’s no need to worry about thousands of lines of code for we’ve done it many times for our clients. Whatever your specific needs are, we can build the product that will satisfy you and your business, along with the clients who you are working with</p>
                <div class="getstarttrans d-flex flex-column flex-md-row">
                    <span class="d-flex justify-center mr-0 mr-md-2" @click.stop="$refs.getstarted.changeDialogget()"><button-standard-large class="py-7 px-14 my-2" large="true" btnclass="btn-border-radius bg-blue white--text" hoverable="true">Get Started</button-standard-large></span>
                    <get-started-block  ref="getstarted"/>
                </div>
            </div>
        </v-container>
        <v-container>
            <div class="py-10 borderbottomop02">
                <v-row class="mt-16 pt-10">
                    <v-col cols="12"  md="5">
                        <p class="h4 montserrat fontw-600 text-center text-md-left">We are using modern technologies that help us solve any issue you might have.</p>
                    </v-col>
                    <v-col cols="12" md="7">
                        <div v-for="(item,i) in technologyblock" class="pb-6">
                            <technology-block :item="item"/>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-container>
        <div class="bg-blue">
            <v-container class="py-7 mt-5 relative">
                <div class="rectangle-bgmainblue d-none d-md-block"></div>
                <div class="py-16 my-16 montserrat">
                    <p class="h6 white--text d-none d-md-block">Answer 5 questions and get $500!</p>
                    <p class="h2 white--text bold">Answer just 4 questions and we’ll see if we match each other!</p>
                    <p class="h4 white--text">For each question you are going to get a $100 discount.</p>
                    <p class="h6 white--text d-block d-md-none">Answer 5 questions and get $500!</p>
                </div>
                <div class="my-10 py-10">
                    <span @click.stop="$refs.getstarted.changeDialogget()">
                        <button-standard-large large="true" btnclass="btn-border-radius py-7 px-16 blue1 removebefore" hoverable="true">Get Started</button-standard-large>
                    </span>
                    <div class="mt-16 pt-7 ml-16 pl-8 d-none d-md-block">
                        <img class="" :src="require('@/assets/icon/dotwhite.svg')">
                    </div>
                </div>
            </v-container>
        </div>
    </div>
</template>

<script>
import TechnologyBlock from '@/components/base/TechnologyBlock.vue'
import GetStartedBlock from '@/components/base/GetStartedBlock.vue'


export default {
    components:{
        TechnologyBlock,
        GetStartedBlock,
    },
    data:()=> ({
        technologyblock:[
            {
                icon: require('@/assets/icon/nodejs.svg'),
                title: 'Node.JS',
                text: 'Laravel is a great PHP framework that encompasses 35k developers from all over the world and almost 500 GitHub contributors. You might wonder, why is Laravel so popular? Our PHP developers appreciate numerous points of its functionality, not to mention its perfect built-in security algorithms.',
            },
            {
                icon: require('@/assets/icon/php.svg'),
                title: 'PHP',
                text: 'It is one of the most popular programming languages used in GBMF for handling software projects. Thanks to the flexibility of PHP, it is great for both small websites and business mega apps.',
            },
            {
                icon: require('@/assets/icon/react.svg'),
                title: 'React',
                text: 'The reason we use Python so much is its both good for creating products that suit start-ups and corporate enterprises. It’s quite versatile, and we can play it like a damn fiddle. The world’s largest information technology companies such as Facebook, Google, Spotify, Netflix, Instagram, IBM have successfully placed a bet on Python as well.',
            },
        ],
    }),
}
</script>

<style lang="scss">
.firstletterblue::first-letter{
    color: #38A4DC;
}

</style>
