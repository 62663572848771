<template>
    <div class="py-10 my-10">
        <div class="weryhighttext text-center mb-10 pb-10">404</div>
        <div class="h2 text-center pb-10">Page not found</div>
        <div class="mb-15">
            <div class="text-center">The requested page was not found. </div>
            <div class="text-center">Return to the main page to continue</div>
        </div>
        <div class="text-center"><custom-button class="py-7 px-14 my-2 btn-border-radius btnfullblue white--text fontw-700 pointer" :to="{name: 'frontend.home'}" large>Back to the main page</custom-button></div>
    </div>
</template>
<script>
import CustomButton from '@/components/base/CustomButton.vue'

export default{
    components: {
        CustomButton
    }
}
</script>

<style lang="scss">
.weryhighttext{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 200px;
    line-height: 196px;
    color: #1E5CFB;
}
</style>