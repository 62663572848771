<template>
    <div class="">

        <v-row v-for="(item, i) in tech" :key="i" class="my-6">
            <v-col cols="12" md="4">
                <div class="h3 fontw-700 montserrat text-center text-sm-left" v-html="technologyblock[i]"></div>
            </v-col>
            <v-col cols="12" md="8" class="mb-8">
                <v-row>
                    <v-col cols="6" sm="3" v-for="(itm, j) in item" :key="j">
                        <div class="d-flex flex-column align-center mt-3 mb-9 tehnologyitem">
                            <img class="tehnologyimage" :src="technologyIcons[itm.slug]" width="65px">
                            <p class="mb-0 my-5 tehnologytext montserrat" v-html="itm.name"></p>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
            <!-- <div class="borderbottomop02 py-5">
                <v-row>
                    <v-col class="text-center text-md-left" cols="12" md="1">
                        <img :src="requireImage(item.icon)">
                    </v-col>
                    <v-col cols="12" lg="3">
                        <p class="h4 montserrat bold text-left">{{item.title}}</p>
                    </v-col>
                    <v-col cols="12" lg="9" class="px-0">
                        <p class="opensans h5-5 px-3 text-left">{{item.description}}</p>
                    </v-col>
                </v-row>
            </div> -->

    </div>
</template>

<script>
export default {
    name:"TechnologyBlock",
    props:[
        'items'
    ],
    data:() => ({
        defaulttech:{
            frontend: [
                {
                    name: 'JavaScript',
                    slug: 'javascript',
                },
                {
                    name: 'React',
                    slug: 'react',
                },
                {
                    name: 'Angular',
                    slug: 'angular',
                },
                {
                    name: 'Vue.js',
                    slug: 'vuejs'
                },
            ],
            backend: [
                {
                    name: 'Python',
                    slug: 'python'
                },
                {
                    name: 'Java',
                    slug: 'java'
                },
                {
                    name: 'PHP',
                    slug: 'php'
                },
                {
                    name: 'Symfony',
                    slug: 'symfony'
                },
                {
                    name: 'Node.js',
                    slug: 'nodejs'
                },
                {
                    name: 'Swift',
                    slug: 'swift'
                },
                {
                    name: 'Kotlin',
                    slug: 'kotlin'
                },
                {
                    name: 'Golang',
                    slug: 'golang'
                },
            ]
        },
        technologyblock:{
            frontend: 'Frontend<br/>development',
            backend: 'Backend<br/>development',
        },
        technologyIcons: {
            'javascript': require('@/assets/img/tehnologies/js.svg'),
            'react': require('@/assets/img/tehnologies/react.svg'),
            'vuejs': require('@/assets/img/tehnologies/vue.svg'),
            'angular': require('@/assets/img/tehnologies/angular.svg'),
            'python': require('@/assets/img/tehnologies/py.svg'),
            'java': require('@/assets/img/tehnologies/java.svg'),
            'php': require('@/assets/img/tehnologies/php.svg'),
            'symfony': require('@/assets/img/tehnologies/symfony.svg'),
            'nodejs': require('@/assets/img/tehnologies/node.svg'),
            'swift': require('@/assets/img/tehnologies/swift.svg'),
            'kotlin': require('@/assets/img/tehnologies/kotlin.svg'),
            'golang': require('@/assets/img/tehnologies/golang.svg'),
            'laravel': require('@/assets/img/tehnologies/laravel.svg'),
            'jwt': require('@/assets/img/tehnologies/jwt.svg'),
            'rbac': require('@/assets/img/tehnologies/rbac.svg'),
            'casl': require('@/assets/img/tehnologies/casl.svg'),
            'mongodb': require('@/assets/img/tehnologies/mongodb.svg'),
            'doker': require('@/assets/img/tehnologies/doker.svg'),
            'realm': require('@/assets/img/tehnologies/realm.svg'),
            'retrofit': require('@/assets/img/tehnologies/retrofit.svg'),
            'redux': require('@/assets/img/tehnologies/redux.svg'),
            'antd': require('@/assets/img/tehnologies/antd.svg'),
            'mysql': require('@/assets/img/tehnologies/mysql.svg'),
            'firebase': require('@/assets/img/tehnologies/firebase.svg'),
            'cassandradb': require('@/assets/img/tehnologies/cassandradb.svg'),
            'crossbar': require('@/assets/img/tehnologies/crossbar.svg'),
            'postgre': require('@/assets/img/tehnologies/postgre.svg'),
            'redis': require('@/assets/img/tehnologies/redis.svg'),
            'restapi': require('@/assets/img/tehnologies/restapi.svg'),
            'sqlserver': require('@/assets/img/tehnologies/sqlserver.svg'),
            'vuetify': require('@/assets/img/tehnologies/vuetify.svg'),
            'bootstrap': require('@/assets/img/tehnologies/bootstrap.svg'),
        },
        tech: {}
    }),
    mounted(){
        if(this.items){
            this.tech = this.items
        } else {
            this.tech = this.defaulttech
        }
    },
    methods: {
        requireImage(image){
            if(image)
                return require('@/assets' + image);
        }
    }
}
</script>

<style lang="scss">
@media all and (max-width:660px){
    .tehnologytext{
        font-size: 18px;
    }
}
@media all and (min-width:660px){
    .tehnologytext{
        font-size: 20px;
    }
}
@media all and (min-width: 930px){
    .tehnologyitem:hover{
        .tehnologyimage{
            opacity: 0.2;
        }
    }
}
</style>
