<template>
    <div class="">
        <div class="zi-1 rectangle-bghead d-none d-lg-block">
        </div>
        <v-container>
            <div class="py-10 mt-lg-16 pt-lg-8">
                <h1 class="h1 montserrat text-center autolineheight text-md-left mb-9 fontw-300" :style="$vuetify.breakpoint.mdAndUp ? 'min-height:234px;': ''">We Do <br/>{{welcometext}}</h1>
                <div class="getstarttrans d-flex flex-column flex-md-row py-1">
                    <span class="d-flex justify-center mr-0 mr-md-2" @click.stop="$refs.getstarted.changeDialogget()">
                        <custom-button class="py-7 px-14 my-2 btn-border-radius btnfullblue white--text fontw-700" large>Get Started</custom-button>
                    </span>
                    <get-started-block  ref="getstarted"/>
                    <span class="d-flex justify-center order-first order-md-2" @click="runErase">
                        <button-refresh class="my-2">Refresh</button-refresh>
                    </span>
                </div>
            </div>
        </v-container>
        <div class="my-16">
            <v-container>
                <category-services/>
            </v-container>
        </div>
		<v-container class="mt-md-16 my-6">
            <!-- <div class="pb-8 borderbottomop02">
                <p class="h2 montserrat bold mt-md-16 text-md-left" data-aos="fade-right">Technology</p>
                <p class="h4 montserrat text-md-left tehnologydesc" data-aos="fade-right">We are using modern technologies that help us solve any issue you might have.</p>
                <technology-block />
            </div> -->
            <tehnologies />
        </v-container>
        <div class="bg-lighten">
            <v-container class="">
                <p class="h2 montserrat bold mt-10 pt-16 mb-md-16">Process</p>

                <v-row class="mb-16">
                    <v-col cols="12" lg="3" v-for="(item, i) in steps" :key="i" class="mt-6">
                        <div class="processcard white rounded-40 p-relative ov-h" data-aos="fade-left" :data-aos-delay="$vuetify.breakpoint.mdAndUp ? (i + 1) * 350 : 100">
                            <div class="processcircletitle">
                                <div class="elipse d-flex align-center mb-1">
                                    <p class="h2 montserrat bold relative mb-0">0{{i + 1}}</p>
                                </div>
                                <p v-if="item.title" class="h4 fontw-700 mt-4 mt-md-0" v-html="item.title"></p>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <div class="pt-16 bg-lighten">
            <v-container>
                <p class="h3 montserrat bold text-center py-10">Industries</p>
                <industries type="cardwhite" :icon="{basetype: 'blue', hovertype: 'white'}"/>
            </v-container>
        </div>
        <quest />
    </div>
</template>

<script>
import GetStartedBlock from '@/components/base/GetStartedBlock.vue'
import ServiceCard from '@/components/base/ServiceCard.vue'
import CategoryServices from '@/components/page/services.vue'
import Industries from '@/components/page/industries.vue'
import Quest from '@/components/page/quest.vue'
import CardCircleTitle from '@/components/base/CardCircleTitle.vue'
import TitleDotted from '@/components/base/TitleDotted.vue'
import CustomButton from '@/components/base/CustomButton.vue'
import Tehnologies from '@/components/page/tehnologies.vue'


export default {
    metaInfo() {
        return {
            meta: [
                { name: 'description', content:  'Bring your most daring ideas to life. GBMF offers services that support your business with top web applications, mobile applications, and software solutions for any area of activity.'},
                { property: 'og:title', content: "GBMF Services | A vast range of IT products for your business"},
                { property: 'og:site_name', content: 'GBMF'},
                { property: 'og:type', content: 'website'},
                { name: 'robots', content: 'index,follow'}
            ]
        }
    },
    components:{
        ServiceCard,
        CategoryServices,
        GetStartedBlock,
        CardCircleTitle,
        TitleDotted,
        Industries,
        Quest,
        CustomButton,
		Tehnologies
    },
    data:()=>({
        refreshtext: [
            'CRM System',
            'Business Websites',
            'E-commerce marketplace',
            'iOS development',
            'Android development',
            'UI/UX design',
            'DevOps consulting',
            'Tech stack recommendation'
        ],
        currentWord: '',
        welcometext: '',
        steps: [
            {
                title: 'Planning',
            },
            {
                title: 'Defining'
            },
            {
                title: 'Designing',
            },
            {
                title: 'Building'
            },
            {
                title: 'Testing'
            },
            {
                title: 'Deployment'
            },
            {
                title: 'Finishing',
            },
            {
                title: 'Maintenance',
            },
        ],
        typeValue: '',
        typeStatus: false,
        charIndex: 0,
        typingSpeed: 100,
        erasingSpeed: 75,
        refreshtextposition: 0,
    }),
    created() {
        this.$emit('headerClass', '');
    },
    mounted(){
        this.runType(false);
    },
    methods: {
        runType(next = true){
            if(this.typeStatus == false){
                if(next){
                    this.refreshtextposition += 1;
                    if(!this.refreshtext[this.refreshtextposition])
                        this.refreshtextposition = 0;
                }

                this.currentWord = this.refreshtext[this.refreshtextposition];
                this.typeText();
            }
        },
        typeText() {
            if(this.charIndex < this.currentWord.length) {
                if(!this.typeStatus)
                    this.typeStatus = true;
                this.welcometext += this.currentWord.charAt(this.charIndex);
                this.charIndex += 1;
                setTimeout(this.typeText, this.typingSpeed);
            }else{
                this.typeStatus = false;
            }
        },
        runErase(){
            if(this.typeStatus == false){
                this.typeArrayIndex = 0;
                this.charIndex = 0;
                setTimeout(this.eraseText, this.erasingSpeed);
            }
        },
        eraseText() {
            if(this.welcometext.length > 0) {
                this.charIndex = this.welcometext.length;
                if(!this.typeStatus)
                    this.typeStatus = true;
                this.welcometext = this.welcometext.substring(0, this.charIndex - 1);
                setTimeout(this.eraseText, this.erasingSpeed);
            }else{
                this.charIndex = 0;
                this.typeStatus = false;
                setTimeout(this.runType, this.typingSpeed);
            }
        },
    }
}
</script>

<style lang="scss">
.firstletterblue::first-letter{
    color: #1E5CFB;
}
.processcard{
    .processcircletitle{
        padding: 40px;
        .elipse::before{
            content: " ";
            margin-left: -38px;
            position: absolute;
            display: inline-block;
            width: 114px !important;
            height: 114px !important;
            background: #1E5CFB;
            opacity: 0.1;
            transition: 0.3s;
            border-radius: 40% 17% 52% 48% / 35% 44% 54% 17%  !important;
            margin-top: -30px;
        }
    }
}
@media all and (min-width: 930px){
    .processcard:hover{
        .elipse::before{
            opacity: 1;
        }
        .elipse{
            color: #ffffff;
        }
    }
}
</style>
