<template>
    <div class="">
        <div class="contact mb-8">
            <v-container>
                <h1 class="h1 montserrat text-center text-sm-left mb-5 mb-sm-10 mb-lg-16 mt-lg-16 pt-lg-10">Contact Us</h1>
                <v-row class="addresses">
                    <v-col cols="12" lg="4">
                        <v-row class="addresses">
                            <v-col cols="12" sm="12" md="4" lg="12">
                                <div class="mb-8">
                                    <p class="h3 fontw-700 mb-2 montserrat">United Kingdom</p>
                                    <p class="h6 mb-4 montserrat">London,1st Floor, 2 Woodberry Grove <br/>Finchley N12 0DR</p>
                                    <a href="mailto:hello@gbmf.tech" class="dark1">
                                        <p class="h5 fontw-700 d-flex align-center montserrat">
                                            <img :src="require('@/assets/icon/email-write.svg')" class="mr-2" alt="">hello@gbmf.tech
                                        </p>
                                    </a>
                                </div>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="12">
                                <div class="mb-8">
                                    <p class="h3 fontw-700 mb-2 montserrat">USA</p>
                                    <p class="h6 mb-4 montserrat">Citrus Heights,California 95610, <br/>7610 Auburn Blvd 4</p>
                                    <a href="mailto:info@gbmf.tech" class="dark1">
                                        <p class="h5 fontw-700 d-flex align-center montserrat">
                                            <img :src="require('@/assets/icon/email-write.svg')" class="mr-2" alt="">info@gbmf.tech
                                        </p>
                                    </a>
                                </div>
                            </v-col>
	                        <v-col cols="12" sm="12" md="4" lg="12">
		                        <div class="mb-8">
			                        <p class="h3 fontw-700 mb-2 montserrat">Switzerland</p>
			                        <p class="h6 mb-4 montserrat">Badenerstrasse 47, 8004 Zürich</p>
			                        <a href="mailto:info@gbmf.tech" class="dark1">
				                        <p class="h5 fontw-700 d-flex align-center montserrat">
					                        <img :src="require('@/assets/icon/email-write.svg')" class="mr-2" alt="">info@gbmf.tech
				                        </p>
			                        </a>
		                        </div>
	                        </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" lg="8">
                        <div class="">
                            <div class="card pa-sm-10 btn-border-radius" :class="{'bg-white': $vuetify.breakpoint.mdAndUp}">
                                <div class="h3 dark1 fontw-700 mb-6">Request to Contact</div>
                                <v-form
                                    lazy-validation
                                    ref="form"
                                    v-model="valid"
                                >
                                    <v-row>
										<v-col cols="12" sm="6">
                                            <label class="dark1 h6 mb-2" for="">First Name</label>
                                            <v-text-field
                                                v-model="form.firstname"
                                                color="#323232"
                                                class="py-0 mt-0"
                                                :rules="requiredRules"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <label class="dark1 h6 mb-2" for="">Last Name</label>
                                            <v-text-field
                                                v-model="form.lastname"
                                                color="#323232"
                                                class="py-0 mt-0"
                                                :rules="requiredRules"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12">
                                            <label class="dark1 h6 mb-2" for="">Phone number</label>
											<div class="d-flex">
												<v-select
													v-model="form.codephone"
													:items="phones"
													:item-text="item => item.dial_code + ' ' + item.name"
													:item-value="item => item.dial_code"
													color="#323232"
													class="py-0 mt-0 mr-3"
													:rules="requiredRules"
													required
													style="width: 100px;"
												>
												</v-select>
	                                            <v-text-field
	                                                v-model="form.phone"
	                                                color="#323232"
	                                                class="py-0 mt-0"
	                                                :rules="phoneRules"
	                                                required
	                                            ></v-text-field>
											</div>
                                        </v-col>
										<v-col cols="12" sm="6">
                                            <label class="dark1 h6 mb-2" for="">Company Name</label>
                                            <v-text-field
                                                v-model="form.company"
                                                color="#323232"
                                                class="py-0 mt-0"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <label class="dark1 h6 mb-2" for="">Your Email</label>
                                            <v-text-field
                                                v-model="form.email"
                                                color="#323232"
                                                class="py-0 mt-0"
                                                :rules="emailRules"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <div class="">
                                        <label class="dark1 h6 mb-2"for="">Tell us about your project</label>
                                        <v-textarea
                                            v-model="form.message"
                                            color="#323232"
                                            rows="8"
                                            class="py-0 mt-0"
                                            :rules="requiredRules"
                                            required
                                        >
                                        </v-textarea>
                                    </div>
                                    <div class="d-md-flex align-center mt-10">
                                        <custom-button class="px-14 py-7 white--text fontw-700 btn-border-radius mb-3 btnfullblue" large @click="apply">Apply</custom-button>
                                        <div class="ml-md-6 h6 opensans ">
                                            By submitting the form you <br/>agree with <router-link :to="{ name: 'frontend.terms', params: {} }" class="dark1 text-decoration-underline">terms and conditions</router-link>
                                        </div>
                                    </div>
									<!-- <div class="clutch-widget" data-url="https://widget.clutch.co" data-widget-type="4" data-expandifr="true" data-height="auto" data-snippets="true" data-clutchcompany-domain="gbmf.tech" data-header-color="#808000" data-header-text-color="#000000" data-footer-color="#808000" data-footer-text-color="#000000" data-primary-color="#808000" data-secondary-color="#bfbf8d" data-background-color="#e5e5cc" data-review-card-color="#e5e5cc"> </div> -->

                                </v-form>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-container>

        </div>
        <v-dialog
            v-model="dialog"
            max-width="560"
            overlay-color="#323232"
            overlay-opacity="0.3"
            content-class="rounded-10 disable-box-shadow"
        >
            <div class="white pa-16 d-flex align-center flex-column">
                <div class="my-5">
                    <img src="@/assets/icon/plane.svg" alt="">
                </div>
                <div class="h3 fontw-700">
                    Thank you!
                </div>
                <p class="my-5 text-center">We have received your message, <br/>and will get back to you soon</p>
                <custom-button class="py-7 text-uppercase px-14 my-2 btn-border-radius btnfullblue white--text fontw-700 h6" large @click="dialog = false">Continue</custom-button>
            </div>
        </v-dialog>
    </div>
</template>

<script>

import CustomButton from '@/components/base/CustomButton.vue'
import ValidationMixin from '@/mixins/validationMixin.js';

import phones from '@/data/phones'

export default {
    metaInfo() {
        return {
            meta: [
                { name: 'description', content:  'Let’s talk about your ideas, your dreams, and your goals, however crazy they might be. Contact GBMF or request a contact to have any of your queries answered.'},
                { property: 'og:title', content: "Contacts - GBMF | Get in touch with the unique IT team"},
                { property: 'og:site_name', content: 'GBMF'},
                { property: 'og:type', content: 'website'},
                { name: 'robots', content: 'index,follow'}
            ]
        }
    },
    components: {
        CustomButton
    },
    mixins: [
        ValidationMixin
    ],
    data: () => ({
        dialog: false,
		phones: phones,
        form: {
			codephone: '+40',
            phone: '',
            email: '',
            text: '',
            file: null
        },
        valid: false
    }),
    created() {
        this.$emit('headerClass', '');
    },
	mounted(){
		if(this.$store.state.location)
			this.form.codephone = this.$store.state.location;


	},
    methods: {
        apply(){
            if(this.$refs.form.validate()){
				// console.log(this.form);
                this.axios.post(process.env.VUE_APP_API + 'sendcontactrequest', this.form).then(response => {
                    this.dialog = true;
                    this.$refs.form.reset();

                });
            }
        }
    }
}
</script>

<style lang="scss">
</style>
